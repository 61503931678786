<template>    
  <v-dialog max-width="1024px" v-model="dialog">
    <v-card style="height:calc(100vh - 200px);" v-resize="onResize">              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          {{cTitle}}
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>  
        <v-spacer></v-spacer>
        <v-btn color="black" icon @click="dialog =false" class="mr-n4"><v-icon>close</v-icon></v-btn>                                       
      </v-toolbar>
      <v-divider></v-divider>            
      <vl-map :load-tiles-while-animating="false" :load-tiles-while-interacting="false" style="height: calc(100vh - 301px)">
        <vl-view :projection="projection" :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>
        <!-- <vl-layer-image id="xkcd"> -->
        <vl-layer-image id="">
          <vl-source-image-static :url="imgUrl" :size="imgSize" :extent="imgExtent" :projection="projection">
          </vl-source-image-static>                                  
          <vl-overlay v-for="(TagPos, i) in zTagPos" :key="i" :offset="[-18, -22]" :position="[TagPos.x*fRateSize, TagPos.y*fRateSize]">            
            <template>                          
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">                                       
                  <v-icon large v-on="on" color="red">mdi-map-marker</v-icon>                                                       
                </template>                    
                <span>{{TagPos.x}}, {{TagPos.y}}</span>
              </v-tooltip>  
            </template>
          </vl-overlay>          
      </vl-layer-image> 
      </vl-map>   

      
      <v-card class="window_info"> 
        <v-card-title class="mb-n2">장비정보</v-card-title>          
        <v-divider class="mx-2"></v-divider> 
        <v-row class="ma-0">
          <v-col cols="12" class="mb-n5"><p class="label">작업자</p><p class="data">{{sUserInfo}}</p></v-col>
          <v-col cols="12" class="mb-n5"><p class="label">맥주소</p><p class="data">{{item.cMac}}</p></v-col>
          <v-col cols="12" class="mb-n5"><p class="label">이벤트</p><p class="data">{{item.cType}}</p></v-col>
          <v-col cols="12" class="mb-n5"><p class="label">발생일시</p><p class="data">{{item.cCreateDateTime}}</p></v-col>          
          <v-col cols="12" class="mb-n0"><p class="label">비고(내용)</p><p class="data">{{memo}}</p></v-col>
          <v-col cols="12" class="mb-n1"><p class="label">위치</p><p class="data">{{posDisplay}}</p></v-col>          
        </v-row>
      </v-card>
      
      
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="black darken-1" text @click="closePage()"
          ><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>
        <!-- <v-btn color="blue darken-1" text @click="SelectData()"
          ><v-icon class="mr-2">check_circle</v-icon>확인</v-btn> -->
        
        
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
import { createProj, addProj } from 'vuelayers/lib/ol-ext'
import BasicInfo from "../BasicInfo.js";

let size2 = [2048, 2048];
let extent2 = [0, 0, ...size2];


let customProj2 = createProj({
  code: 'xkcd-image',
  units: 'pixels',
  extent2,
})

addProj(customProj2);

export default {
  components: {    
  },
  data() {
    return {
      dialog: false,
      cTitle : "",
      fRateSize : 1,
      sDate : "",      
      memo : "",      
      sUserInfo : "",
      posDisplay : "",      
      item : {cMac :"", cType:"", cCreateDateTime:""},
      model : null,     
      zoom: BasicInfo.Map_Zoom_Default,
      center: [size2[0] / 2, size2[1] / 2],
      rotation: 0,
      imgSize: size2,    
      imgExtent: extent2,
      projection: customProj2.getCode(),
      imgUrl : BasicInfo.FILE_API + "Files/NoImage.svg",
      zTagPos : [],
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {
    this.onResize()
  },
  methods: {    
    closePage: function() {                
      this.dialog = false;
    },   
    onResize () {
      

      size2 = [window.innerWidth, window.innerWidth];
      extent2 = [0, 0, ...size2];

      this.imgSize = size2;
      this.imgExtent = extent2;
      this.center = [size2[0] / 2, size2[1] / 2];

      this.fRateSize = window.innerWidth/2048;

      this.zoom = 1;
    },
    Show(title, data, path,  x, y, memo, posDisplay, sUserInfo) {              
      this.cTitle = title;

      console.log(data, memo);

      this.item = data;
      this.memo = memo;
      this.posDisplay = posDisplay;
      this.imgUrl = BasicInfo.FILE_API + path;

      this.sUserInfo = sUserInfo;

      this.zTagPos = [];
      var item = {
        cApId: "1",
        x : x,
        y : y,        
      }
      console.log(item);
      this.zTagPos.push(item);

      setTimeout(() => {
        this.dialog = true;
      }, 100);
      
    },    
  }
};
</script>

<style scoped>
  .window_info{    
    position: absolute;
    margin: auto;
    width : 300px;
    top: 60px;
    right: 10px;
  }

  .label{        
    width : 100px;    
    height : 0px;
    color: #333333;     
  }

  .data{        
    width : 200px;    
    height : 0px;
    top : -17px;
    left : 90px;
    position: relative;    
    font-weight: bold;
  }
</style>