<template>    
  <v-dialog max-width="500px" v-model="dialog">
    <v-card >              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          비상호출 조치
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>                    
      </v-toolbar>
      <v-divider></v-divider>              
      <v-card-text>
        <v-select
          :items="zSOSResult"
          label="유형"
          item-text="sNm"
          item-value="sId"          
          v-model="cType"
        ></v-select>

        <div v-if="cType == cInputTextCd">
          <v-textarea              
            v-model="cCheckData"
            label="조치결과 입력"            
            hint="조치결과를 입력하세요."
            outlined
            no-resiz
          ></v-textarea>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="black darken-1" text @click="closePage()"><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>
        <v-btn color="blue darken-1" text @click="SetData()"><v-icon class="mr-2">check_circle</v-icon>저장</v-btn>        
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';
import { mapState } from "vuex";

export default {
  components: {    
  },
  data() {
    return {
      dialog: false,
      cUserId : "",
      bOneProcess : false,
      sDate : "",      
      model : null,      
      cInputTextCd : "99",
      cType : {value : "00", name : "단순실수"},     
      cSOSIdx : "",
      zSOSIdx : [],
      cTag : "",
      zTag : [],
      cCheckData : "",
      
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },

  computed: {
    ...mapState(["zSOSResult"]),
  },

  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage(){                   
      this.dialog = false;
    },

    SetData() {      
      if (this.bOneProcess) {
        this.zSOSIdx = [this.cSOSIdx];
        this.zTag = [this.cTag];
      }
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        zIdx : this.zSOSIdx,
        zTag : this.zTag,
        cChecked : "Y",        
        cCheckType : this.cType,        
        cCheckData : this.cCheckData,
      };

      // console.log(posData);
      axios
        .post(BasicInfo.UIL_API + "SetSOSCheck", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            this.$emit("onSetSOS", this.cUserId, posData, res.data);
            this.dialog = false;  
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },

    Show: function(cSOSIdx, cTag, cUserId) {
      // console.log(cTag);
      this.bOneProcess = true;
      this.cUserId = cUserId;
      this.cSOSIdx = cSOSIdx;
      this.cTag = cTag;
      this.cType = "00";
      this.cCheckData = "";
      this.dialog = true;      
    },

    ShowSelect: function(zSOSIdx, zTag) {      
      this.bOneProcess = false;
      this.zSOSIdx = zSOSIdx;
      this.zTag = zTag;
      this.cType = "00";
      this.cCheckData = "";
      this.dialog = true;      
    },
  }
};
</script>
