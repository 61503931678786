<template>  
  <div>
    <div class="background_basic">      
      <div style="height:1px"></div>
      <v-card class="ma-4">      
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon class="mr-2">mdi-image-search</v-icon>비상호출 조치결과
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">refresh</v-icon>초기화
          </v-btn>         
          <v-btn color="blue" text @click="ExcelExpert('비상호출 조치결과')" class="ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
          </v-btn>             
          <v-spacer></v-spacer>  
          <v-btn color="blue" text @click="SelectChecked()" class="font-weight-bold text-subtitle-1" :disabled="!ProgramPermissionInfo.okU">
            <v-icon big class="mr-2">mdi-check-underline-circle</v-icon>선택 일괄등록
          </v-btn>           
        </v-toolbar>        
        <v-divider></v-divider> 
        
        <v-card flat class="d-flex ml-3 mr-3">
          <v-card flat class="ml-0 mb-1">             
            <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
          </v-card>                                 
        
          <v-card class="ml-3 mb-1" flat style="max-width:150px;">
            <v-select v-model="cNowCheck" class="mb-n5" label="조치여부" :items="zCheckAll" item-text="sNm" item-value="sId"></v-select>
          </v-card>        
        
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-3 mb-n5"   
            label="검색어"
            placeholder="맥주소 또는 이름 입력"
            persistent-placeholder
            style="max-width:250px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
        
          <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>         
        </v-card>       
      </v-card>
      
      <v-data-table 
        class="ma-4 elevation-3"
        :items-per-page="15"
        fixed-header
        height="calc(100vh - 227px)"
        v-model="selected"
        show-select
        item-key="cIdx"
        :headers="headers" 
        :items="Records"
      >
        <template v-slot:item.cUserNm="{ item }">
          <div v-if="item.cCorpNm != 'NONE' " small label color="grey lighten-3">            
            {{item.cCorpNm + " / " + item.cUserNm}}
          </div>          
          <div v-else-if="item.cUserNm != 'NONE' " small label color="grey lighten-3">{{item.cUserNm}}</div>          
        </template> 
        <template v-slot:item.cChecked="{ item }">
          <v-chip small label v-if="item.cChecked == 'Y'" color="grey lighten-2">조치</v-chip>
          <v-chip small label v-if="item.cChecked == 'N'" dark color="red lighten-2">미조치</v-chip>
        </template>
        <template v-slot:item.cResultChip="{ item }">          
          <div v-if="item.cChecked == 'Y'">
            <v-chip v-if="item.cCheckType=='99'" label small color="grey darken-2" dark>{{item.cCheckData}} / {{item.cUpdateDateTime}}</v-chip>
            <v-chip v-else-if="item.cCheckType=='00'" label small color="grey lighten-2" >단순실수 / {{item.cUpdateDateTime}} </v-chip>
            <v-chip v-else-if="item.cCheckType=='01'" label small color="red darken-2" dark>응급상황 / {{item.cUpdateDateTime}} </v-chip>
          </div>
          <div v-else>
            <v-btn small :color="getColor2(item.cChecked)" dark @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">결과등록</v-btn>
          </div>          
        </template>        
        <template v-slot:item.cLocation="{ item }">
          <v-btn icon color="grey darken-2" dark @click="showPos(item)"><v-icon>mdi-map</v-icon></v-btn>
        </template>
      </v-data-table>

      
      <!-- 조치결과 확인 다이올로그 -->
      <v-dialog v-model="dialog2" max-width="600px" v-if="dialog2">
        <v-card ref="form">
          <v-card-title>
            <span class="font-weight-bold">조치결과 확인</span>
          </v-card-title>
          <v-card-text>
            <v-text-field dense v-model="editedItem.cResult" disabled></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-2" text @click="close" class="font-weight-bold text-subtitle-1">
              <v-icon big class="mr-2">cancel</v-icon>닫기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 
    
      <SetSOS ref="SetSOSRef" @onSetSOS="onSetSOS" /> 
      <MapViewPOS ref="MapViewPOSRef"/> 
      <div style="height:1px"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import BasicInfo from "@/BasicInfo.js"
import { mapState } from "vuex";
import XLSX from 'xlsx'
import Util from "../Util.js"
import EventBus from '@/eventBus.js'
import IronDate from '../components/iron28Calendar/src/IronDate.vue'
import SetSOS from "@/components/SetSOS.vue";
import MapViewPOS from "@/components/MapViewPOS.vue";

//이벤트 이력관리 사이트
export default {
   components: {
     IronDate,
     SetSOS,
     MapViewPOS,
   },

  data: () => ({
    cGroup : "ALL",
    isGroupALL : false,    
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Date()),
    // sNowSelectDate :  Util.sFormatDate(Util.sMonthSel()) + " ~ " +  Util.sFormatDate(Date()),
    // sStartDate : Util.sFormatDate(Util.sMonthSel()),
    sEndDate :Util.sFormatDate(Date()),
    cNowSearchKeyWord : "",
    cNowCheck: "ALL",    
    selected: [],
    dialog1: false, 
    dialog2: false, 
    headers: [      
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '발생일시', value: 'cCreateDateTime'},
      { class: "font-weight-bold subtitle-2", align: "center", text: '작업자', value: 'cUserNm'},
      { class: "font-weight-bold subtitle-2", align: "center", text: '연락처', value: 'cTel', width: 150},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치여부', value: 'cChecked'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '조치결과', value: 'cResultChip'},      
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '위치보기', value: 'cLocation'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '장비 MAC주소', value: 'cTagId'},      
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '장비타입', value: 'cType'},            
      // { class: "font-weight-bold subtitle-3", align: "center", sortable: false, text: '번호', value: 'cIdx', width: 180},
    ],
    Records: [],
    editedIndex: -1,
    editedItem: {
      cIdx: "",
      cTagId: "",
      cResult: "",
      cResultChip: "",  
      cChecked :"",      
    },  
  }),
   
  mounted () {
    this.$store.dispatch("GetUserRoleInfo"); 

    if (this.$store.state.UserInfo.isGroupMode && this.$store.state.UserInfo.GroupId == "ALL") {
      this.isGroupALL = true;
      this.$store.state.GetGroupInfo(); 
    }

    this.initialize()
  },

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zCheckAll", "zGroupAll", ]),
  },

  methods: {
    initialize() {
      this.cNowSearchKeyWord = "";
      this.cNowCheck= "ALL";
      this.sNowSelectDate = Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date());   
      // this.sNowSelectDate = Util.sFormatDate(Util.sMonthSel()) + " ~ " +  Util.sFormatDate(Date());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Date());
      // this.sStartDate = Util.sFormatDate(Util.sMonthSel());
      this.sEndDate = Util.sFormatDate(Date());

      this.Search();
    },

    // 달력에서 시작~종료 날짜 선택
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },


    onSetSOS(_, data, res) {       
      if (this.selected.length == 0) {     
        this.editedItem.cChecked = "Y";              
        this.editedItem.cCheckType = data.cCheckType;              
        this.editedItem.cCheckData = data.cCheckData;     
        
        this.editedItem.cUpdateDateTime = res.Result[0].cUpdateDateTime.substring(0, 19);              
        Object.assign(this.Records[this.editedIndex], this.editedItem);
      } else {
        for (let i=0; i < this.selected.length; i++){
          if (this.selected[i].cChecked == 'N') {
            this.selected[i].cChecked = "Y";              
            this.selected[i].cCheckType = data.cCheckType;              
            this.selected[i].cCheckData = data.cCheckData;               
            this.selected[i].cUpdateDateTime = res.Result[0].cUpdateDateTime.substring(0, 19);
          }
        }

        this.selected = [];
      }
    },
    getColor2(a) {
      if (a == "Y") return "#555555";
      else return "#000000";
    },

    showPos(item){
      if (BasicInfo.Is3D_MapMode) {
        EventBus.$emit("onMapPos", item.cApId)
      } else {
        this.$refs.MapViewPOSRef.Show("발생위치", item, item.cPath, item.x, item.y, "비상호출", item.cMapFullNm, item.cUserNm);
      }      
    },

    Search() {      
      this.Records = [];
      var posData = {
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",
        cNowCheck: this.cNowCheck,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cGroupId: this.$store.state.UserInfo.GroupId,
      };

      if (this.isGroupALL) {
        if (this.cGroup != "ALL") {
          posData.cGroupId = this.cGroup;
        }
      }

      axios
        .post(BasicInfo.UIL_API + "GetSOSRes", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd === "00") { 
            this.Records = res.data.Result;               
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
            console.log("(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);         
          console.log(err); 
        });
    },


    // 항목 선택후 선택일괄등록 클릭시(선택된 cIdx를 nNowSel에 넣어서 axios로 보내서 서버에 저장함)
    // 여러개인경우 cIdx에 Multi을 넣고 zNowSel값을 지정함
    // web화면에 확인컬럼에 'Y'로 바꿈
    SelectChecked(){
      var sel = [];
      var Tag = [];

      for (let i=0; i < this.selected.length; i++){

        if (this.selected[i].cChecked == 'N') {
          sel.push(this.selected[i].cIdx);
          Tag.push(this.selected[i].cTagId);
        }        
      }
      if (sel.length == 0) {
        EventBus.$emit("onShowMsg",true, "선택된 데이터가 없습니다.") 
        return;
      }
      console.log(sel);
      this.$refs.SetSOSRef.ShowSelect(sel, Tag);       
    }, 

    
    editItem(item) {
      this.editedIndex = this.Records.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // console.log(this.editedItem);
      
      if (this.editedItem.cChecked == "N") {      
        this.$refs.SetSOSRef.Show(this.editedItem.cIdx, this.editedItem.cTagId);
      } else {
        this.Search();
        this.dialog2 = true;
      }
    },

    
    close() {      
      this.dialog2 = false;
    },    
    

    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },
  },
  
}

</script>

